exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cfp-js": () => import("./../../../src/pages/cfp.js" /* webpackChunkName: "component---src-pages-cfp-js" */),
  "component---src-pages-coc-js": () => import("./../../../src/pages/coc.js" /* webpackChunkName: "component---src-pages-coc-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-diversity-js": () => import("./../../../src/pages/diversity.js" /* webpackChunkName: "component---src-pages-diversity-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-program-index-js": () => import("./../../../src/pages/program/index.js" /* webpackChunkName: "component---src-pages-program-index-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speakers-index-js": () => import("./../../../src/pages/speakers/index.js" /* webpackChunkName: "component---src-pages-speakers-index-js" */),
  "component---src-pages-sponsor-us-js": () => import("./../../../src/pages/sponsor-us.js" /* webpackChunkName: "component---src-pages-sponsor-us-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-templates-blog-js-content-file-path-content-blog-20240926-a-conversation-with-data-mesh-live-curator-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/blog/20240926-A conversation with Data Mesh Live curator.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-20240926-a-conversation-with-data-mesh-live-curator-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-a-data-mesh-conference-in-this-economy-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/blog/a-data-mesh-conference-in-this-economy.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-a-data-mesh-conference-in-this-economy-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-pre-conference-workshops-andrew-jones-jacek-majchrzak-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/blog/pre-conference-workshops-andrew-jones-jacek-majchrzak.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-pre-conference-workshops-andrew-jones-jacek-majchrzak-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-advanced-software-architecture-with-residuality-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/advanced-software-architecture-with-residuality.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-advanced-software-architecture-with-residuality-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-architecture-as-code-making-architecture-work-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/architecture-as-code-making-architecture-work.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-architecture-as-code-making-architecture-work-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-architecture-the-hard-parts-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/architecture-the-hard-parts.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-architecture-the-hard-parts-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-automating-business-processes-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/automating-business-processes.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-automating-business-processes-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-automating-federated-data-governance-with-sharer-driven-data-contracts-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/automating-federated-data-governance-with-sharer-driven-data-contracts.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-automating-federated-data-governance-with-sharer-driven-data-contracts-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-beyond-big-ideas-strategies-for-continuous-product-innovation-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/beyond-big-ideas-strategies-for-continuous-product-innovation.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-beyond-big-ideas-strategies-for-continuous-product-innovation-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-cqrs-and-event-sourcing-in-the-age-of-durable-execution-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/cqrs-and-event-sourcing-in-the-age-of-durable-execution.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-cqrs-and-event-sourcing-in-the-age-of-durable-execution-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-data-contract-workshop-using-open-source-tooling-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/data-contract-workshop-using-open-source-tooling.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-data-contract-workshop-using-open-source-tooling-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-data-mesh-grand-challenges-closing-the-gap-between-concept-implementation-and-business-impact-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/data-mesh-grand-challenges-closing-the-gap-between-concept-implementation-and-business-impact.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-data-mesh-grand-challenges-closing-the-gap-between-concept-implementation-and-business-impact-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-data-mesh-in-action-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/data-mesh-in-action.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-data-mesh-in-action-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-ddd-surrounded-by-legacy-govt-edition-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/ddd-surrounded-by-legacy-govt-edition.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-ddd-surrounded-by-legacy-govt-edition-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-designing-microservices-responsibilities-apis-and-collaborations-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/designing-microservices-responsibilities-apis-and-collaborations.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-designing-microservices-responsibilities-apis-and-collaborations-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-driving-business-engagement-through-domain-ownership-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/driving-business-engagement-through-domain-ownership.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-driving-business-engagement-through-domain-ownership-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-eliminate-the-complexities-of-event-driven-architecture-with-domain-driven-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/eliminate-the-complexities-of-event-driven-architecture-with-domain-driven-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-eliminate-the-complexities-of-event-driven-architecture-with-domain-driven-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-event-driven-modelling-anti-patterns-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/event-driven-modelling-anti-patterns.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-event-driven-modelling-anti-patterns-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-what-why-and-how-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/event-sourcing-the-what-why-and-how.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-what-why-and-how-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-federated-computational-governace-with-ai-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/federated-computational-governace-with-ai.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-federated-computational-governace-with-ai-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-from-kitchen-to-catalog-applying-the-chefs-discipline-to-data-products-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/from-kitchen-to-catalog-applying-the-chefs-discipline-to-data-products.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-from-kitchen-to-catalog-applying-the-chefs-discipline-to-data-products-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-getting-your-kafka-costs-under-control-a-quest-for-more-transparency-and-accountability-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/getting-your-kafka-costs-under-control-a-quest-for-more-transparency-and-accountability.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-getting-your-kafka-costs-under-control-a-quest-for-more-transparency-and-accountability-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-harnessing-the-durable-execution-pattern-from-failures-to-fault-tolerance-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/harnessing-the-durable-execution-pattern-from-failures-to-fault-tolerance.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-harnessing-the-durable-execution-pattern-from-failures-to-fault-tolerance-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-implementing-a-data-mesh-with-data-contracts-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/implementing-a-data-mesh-with-data-contracts.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-implementing-a-data-mesh-with-data-contracts-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-implementing-tactical-patterns-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/implementing-tactical-patterns.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-implementing-tactical-patterns-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-information-architecture-recommoning-how-standardization-enables-differentiation-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/information-architecture-recommoning-how-standardization-enables-differentiation.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-information-architecture-recommoning-how-standardization-enables-differentiation-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-leadership-in-software-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/leadership-in-software-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-leadership-in-software-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-orchestration-vs-choreography-the-good-the-bad-and-the-trade-offs-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/orchestration-vs-choreography-the-good-the-bad-and-the-trade-offs.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-orchestration-vs-choreography-the-good-the-bad-and-the-trade-offs-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-our-journey-to-an-event-sourcing-middleware-eurostar-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/our-journey-to-an-event-sourcing-middleware-eurostar.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-our-journey-to-an-event-sourcing-middleware-eurostar-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-practical-introduction-to-event-sourcing-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/practical-introduction-to-event-sourcing.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-practical-introduction-to-event-sourcing-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-productizing-data-along-the-value-chain-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/productizing-data-along-the-value-chain.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-productizing-data-along-the-value-chain-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-put-an-ml-model-in-production-with-me-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/put-an-ml-model-in-production-with-me.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-put-an-ml-model-in-production-with-me-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-so-you-want-to-maintain-a-reliable-event-driven-system-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/so-you-want-to-maintain-a-reliable-event-driven-system.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-so-you-want-to-maintain-a-reliable-event-driven-system-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-software-modeling-with-tla-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/software-modeling-with-tla.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-software-modeling-with-tla-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-strategic-domain-driven-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/strategic-domain-driven-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-strategic-domain-driven-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-system-design-as-system-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/system-design-as-system-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-system-design-as-system-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-boeing-737-max-when-humans-and-technology-dont-mix-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-boeing-737-max-when-humans-and-technology-dont-mix.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-boeing-737-max-when-humans-and-technology-dont-mix-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-invisible-governance-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-invisible-governance.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-invisible-governance-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-magic-of-small-things-microservices-10-years-on-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/the-magic-of-small-things-microservices-10-years-on.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-magic-of-small-things-microservices-10-years-on-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-unifying-databases-and-streaming-systems-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/unifying-databases-and-streaming-systems.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-unifying-databases-and-streaming-systems-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-versioning-event-driven-systems-change-is-inevitable-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/versioning-event-driven-systems-change-is-inevitable.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-versioning-event-driven-systems-change-is-inevitable-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-visualising-software-architecture-with-the-c-4-model-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/visualising-software-architecture-with-the-c4-model.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-visualising-software-architecture-with-the-c-4-model-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-what-we-talk-about-when-we-talk-about-data-products-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/what-we-talk-about-when-we-talk-about-data-products.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-what-we-talk-about-when-we-talk-about-data-products-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-why-data-contracts-are-the-foundations-of-a-data-mesh-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/program/why-data-contracts-are-the-foundations-of-a-data-mesh.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-why-data-contracts-are-the-foundations-of-a-data-mesh-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-acerbis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/alberto-acerbis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-acerbis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alexander-rovner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/alexander-rovner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alexander-rovner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-andrea-gioia-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/andrea-gioia.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-andrea-gioia-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-jones-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/andrew-jones.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-jones-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-anita-kvamme-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/anita-kvamme.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-anita-kvamme-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-arif-wider-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/arif-wider.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-arif-wider-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-barry-oreilly-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/barry-oreilly.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-barry-oreilly-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chelsea-troy-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/chelsea-troy.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chelsea-troy-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chris-richardson-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/chris-richardson.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chris-richardson-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-dr-simon-harrer-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/dr-simon-harrer.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-dr-simon-harrer-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-emma-houben-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/emma-houben.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-emma-houben-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-gien-verschatse-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/gien-verschatse.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-gien-verschatse-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-hillel-wayne-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/hillel-wayne.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-hillel-wayne-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ian-cooper-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/ian-cooper.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ian-cooper-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jacek-majchrzak-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jacek-majchrzak.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jacek-majchrzak-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-james-eastham-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/james-eastham.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-james-eastham-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-james-lewis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/james-lewis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-james-lewis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jean-georges-perrin-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jean-georges-perrin.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jean-georges-perrin-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jochen-christ-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jochen-christ.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jochen-christ-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-john-phamvan-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/john-phamvan.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-john-phamvan-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jorg-schad-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/jorg-schad.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jorg-schad-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-karin-hakansson-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/karin-hakansson.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-karin-hakansson-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kinda-el-maarry-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/kinda-el-maarry.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kinda-el-maarry-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kiran-prakash-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/kiran-prakash.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kiran-prakash-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kyle-kotowick-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/kyle-kotowick.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kyle-kotowick-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-laila-bougria-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/laila-bougria.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-laila-bougria-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-marc-klefter-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/marc-klefter.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-marc-klefter-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mark-richards-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/mark-richards.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mark-richards-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-maxime-sanglan-charlier-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/maxime-sanglan-charlier.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-maxime-sanglan-charlier-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mihai-budiu-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/mihai-budiu.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mihai-budiu-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-neal-ford-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/neal-ford.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-neal-ford-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-oskar-dudycz-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/oskar-dudycz.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-oskar-dudycz-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-paolo-rodeghiero-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/paolo-rodeghiero.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-paolo-rodeghiero-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-rachel-barton-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/rachel-barton.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-rachel-barton-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ralph-m-debusmann-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/ralph-m-debusmann.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ralph-m-debusmann-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ruth-malan-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/ruth-malan.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ruth-malan-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-sheen-brisals-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/sheen-brisals.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-sheen-brisals-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-simon-brown-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/simon-brown.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-simon-brown-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-coopman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/thomas-coopman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-coopman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-zsofia-herendi-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.datameshlive.com/2025.datameshlive.com/content/speakers/zsofia-herendi.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-zsofia-herendi-mdx" */)
}

